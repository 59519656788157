/* eslint-disable max-lines-per-function */
import './index.scss'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import SocialList from '../SocialList'
import cn from 'classnames'
import login from '../../assets/img/icons/login.svg'
import logo from '../../assets/img/icons/logo.svg'
import styled, { css } from 'styled-components'
import useTogglePopup from '../Modal/useTogglePopup'
import { AlreadyWorkingModal } from './AlreadyWorkingModal'
import { ArrowSlim } from './ArrowSlim'
import { Avatars, Icons, Text } from '../../../youtalk-storybook/src/ui'
import { Button } from '../../atoms/Button'
import { DropdownMenu } from '../../atoms/DropdownMenu'
import { Event as GAEvent } from '../GA'
import { HeaderPickUp, HeaderPickUpSm } from './PickUp'
import { LKEnterPopup } from '../../organisms/LKEnterPopup'
import { Link } from 'gatsby'
import { createPortal } from 'react-dom'
import { lkURL, size } from '../../constants'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useLocation } from '@reach/router'
import { useLoggedUsername } from './useLoggedUsername'
import { useNavData } from './useNavData'
import { useSaveReferalParameter } from '../../atoms/useSaveReferalParameter'
import { useSelector } from 'react-redux'

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 8px;

  @media (max-width: ${size.md}) {
    gap: 8px;
  }
`

const ButtonLoginIcon = styled(Button.NewOutline)`
  width: 48px;
  height: 48px;
`

const ButtonLogin = styled(Button.NewOutline)`
  width: 80px;
  height: 48px;

  @media (max-width: ${size.xs}) {
    height: 36px;
  }
`

export const clearSessionStorage = () => {
  localStorage.removeItem('username')
  localStorage.removeItem('auth')
  window.sessionStorage.removeItem('auth_token')
}

const AccountDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const AvatarButton = styled(Button.NewOutline)`
  padding: 0;
  border: none;
`

const LinkWithIcon = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const linkStyles = css`
  user-select: none;
  align-items: baseline;
  display: inline-block;
  padding: 0 24px;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: #333333;
  display: flex;
  white-space: normal;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  & > p {
    padding-block: 12px;
  }
`

const TitleLink = styled.div`
  ${linkStyles}
  color: ${({ color }) => color ?? '#333333'};
  cursor: pointer;
  &:hover {
    color: #03a094;
  }
  &:active {
    background: #e6e6e6;
    background-color: #e6e6e6;
  }
`

const MenuLink = styled.div`
  ${linkStyles}
  padding: 0;
  cursor: pointer;
  &:hover {
    color: #03a094;
  }
  &:focus {
    outline: none;
  }
`

const Subtitle = styled.div`
  ${linkStyles}
  cursor: default;
  }
`

const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: #000;
  margin-top: 22px;
  align-self: flex-start;
`
const CollapseBlock = styled.div.attrs(({ bg }) => bg)`
  background-color: ${({ bg }) => bg};
`

const MenuLinkWithDot = styled(Link)`
  ${linkStyles}
  gap: 12px;
  padding-left: 36px;
  cursor: pointer;
  &:hover {
    color: #03a094;
    & > ${Dot} {
      background-color: #028076;
    }
  }
`

const LKEnter = styled(({ className, onlyAvatar, toggleLkEnterPopup }) => {
  const [loggedUserName] = useLoggedUsername()
  const wWidth = useSelector(selectDeviceSize)
  return (
    <div className={className}>
      {wWidth > 767 ? (
        <DropdownMenu
          arrowHide={onlyAvatar}
          customClassName="nav-list__item"
          menuItems={[
            {
              name: (
                <LinkWithIcon>
                  <Icons.IconPerson />
                  <p>Личный кабинет</p>
                </LinkWithIcon>
              ),
              path: lkURL,
              type: 'externalLink'
            },
            {
              name: (
                <LinkWithIcon>
                  <Icons.IconEnter />
                  <p>Выйти</p>
                </LinkWithIcon>
              ),
              type: 'button',
              onClick: () => {
                clearSessionStorage()
                window.location.reload()
              }
            }
          ]}
        >
          <AccountDropdown>
            <Avatars.AvatarClosed2 />
            {!onlyAvatar && <p>{loggedUserName}</p>}
          </AccountDropdown>
        </DropdownMenu>
      ) : (
        <AvatarButton onClick={() => toggleLkEnterPopup()}>
          <Avatars.AvatarClosed2 />
        </AvatarButton>
      )}
    </div>
  )
})``

const Header = ({ hideBtn = false, autoScrollOff = false }) => {
  useSaveReferalParameter('pt_promocode')
  const [showedMenus, setShowedMenus] = useState(undefined)
  const isMenuShowed = (menu) => showedMenus === menu
  const toggleMenu = (menu) => () =>
    setShowedMenus((prevMenu) => (prevMenu === menu ? undefined : menu))

  const nav = useNavData()
  const [loggedUserName] = useLoggedUsername()

  const location = useLocation()
  const { isShowing, tglPopup } = useTogglePopup()

  const { tglPopup: toggleLkEnterPopup, isShowing: lkEnterPopupShowing } =
    useTogglePopup()

  const wWidth = useSelector(selectDeviceSize)

  const refRoot = useRef(null)

  useEffect(() => {
    refRoot.current = document.querySelector('.root')
  }, [])

  useEffect(() => {
    const listener = (e) => {
      if (e?.origin !== 'https://widget.giftery.cards') {
        return
      }

      if (e?.data?.event === 'purchaseCertificateYouTalk') {
        window?.dataLayer?.push({
          event: 'certPurch',
          action: 'purchase',
          label: e?.data?.sum
        })
      }
    }
    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  useEffect(() => {
    refRoot.current.classList.remove('root_fixed')
    if (autoScrollOff) return

    refRoot.current.scrollTo(0, 0)
  }, [refRoot])

  const [menuDisplay, setMenuDisplay] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [st, setSt] = useState(0)
  const [prevSt, setPrevSt] = useState(0)

  useEffect(() => {
    let timeout = null
    const handleScroll = () => {
      timeout = setTimeout(() => {
        setSt(refRoot.current.scrollTop)
        setSticky(prevSt < st && refRoot.current.scrollTop > 100)
        setPrevSt(st)
      }, 100)
    }

    refRoot.current.addEventListener('scroll', handleScroll)

    return () => {
      refRoot.current.removeEventListener('scroll', handleScroll)
      clearTimeout(timeout)
    }
  }, [prevSt, st, refRoot])

  const handleTglMenu = () => {
    if (menuDisplay) {
      setMenuVisible(false)
      setTimeout(() => {
        setMenuDisplay(false)
        refRoot.current.classList.remove('root_fixed')
      }, 300)
    } else {
      setMenuDisplay(true)
      refRoot.current.classList.add('root_fixed')
      setTimeout(() => {
        setMenuVisible(true)
      }, 0)
    }
  }

  const handleOpenDropdown = useCallback(() => {
    GAEvent.OpenMaterials()
  }, [])

  let classHeaderMenu = cn('header__nav header-nav', {
    header__nav_visible: menuVisible
  })

  let classBurger = cn('btn-burger', {
    'btn-burger_open': menuVisible,
    'btn-burger_close': !menuVisible
  })

  let classPortalBg = cn('portal-bg', {
    'portal-bg_visible': menuVisible
  })

  return (
    <>
      <header
        className={cn('header', {
          header_transform: sticky && wWidth < 1023
        })}
      >
        <div className="container">
          <div className="row">
            <div className="designer-col col-12">
              <div className="header__wrap">
                {location.pathname === '/' ? (
                  <div className="header__logo">
                    <img alt="You Talk" src={logo} />
                  </div>
                ) : (
                  <Link className="header__logo" to="/">
                    <img alt="You Talk" src={logo} />
                  </Link>
                )}

                {wWidth > 1023 && (
                  <nav className={classHeaderMenu}>
                    <ul className="header-nav__list nav-list">
                      {nav.map(({ path = '', name, nested = [] }) => {
                        let nestedLength = nested.length !== 0
                        return (
                          <li
                            key={name}
                            className={cn('nav-list__item', {
                              'nav-list__item_dropdown': nestedLength
                            })}
                          >
                            {nestedLength ? (
                              <>
                                <DropdownMenu
                                  customClassName="nav-list__item"
                                  menuItems={nested}
                                  onOpen={handleOpenDropdown}
                                >
                                  {name}
                                </DropdownMenu>
                              </>
                            ) : (
                              <Link
                                partiallyActive
                                activeClassName="active"
                                to={path}
                              >
                                {name}
                              </Link>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                    {loggedUserName ? (
                      <LKEnter onlyAvatar={false} />
                    ) : (
                      <ButtonGroup>
                        <HeaderPickUp hidden={hideBtn} />
                        {wWidth < 1440 && wWidth >= 1024 ? (
                          <ButtonLoginIcon iconOnly onClick={tglPopup}>
                            <img alt="Войти" src={login} />
                          </ButtonLoginIcon>
                        ) : (
                          <ButtonLogin onClick={tglPopup}>Войти</ButtonLogin>
                        )}
                      </ButtonGroup>
                    )}
                  </nav>
                )}
                {wWidth < 1024 && menuDisplay
                  ? createPortal(
                      <>
                        {wWidth > 767 && (
                          <>
                            <div
                              className={classPortalBg}
                              onClick={handleTglMenu}
                            ></div>
                          </>
                        )}
                        <nav className={classHeaderMenu}>
                          <ul className="header-nav__list nav-list">
                            {nav.map(({ path, name, nested = [] }) => (
                              <React.Fragment key={name}>
                                {nested.length !== 0 ? (
                                  <CollapseBlock
                                    bg={isMenuShowed(name) ? '#F2F2F2' : '#FFF'}
                                  >
                                    <li className="nav-list__item">
                                      <TitleLink
                                        color={
                                          isMenuShowed(name) ? '#03A094' : ''
                                        }
                                        onClick={toggleMenu(name)}
                                      >
                                        <Text.Medium bold>{name}</Text.Medium>
                                        {isMenuShowed(name) ? (
                                          <ArrowSlim />
                                        ) : (
                                          <ArrowSlim rotate={180} />
                                        )}
                                      </TitleLink>
                                    </li>
                                    {isMenuShowed(name)
                                      ? nested.map((el, i) =>
                                          el.children ? (
                                            <div key={`nestedTitle${i}`}>
                                              <li className="nav-list__item">
                                                <Subtitle>
                                                  <Text.Medium bold>
                                                    {el.name}
                                                  </Text.Medium>
                                                </Subtitle>
                                              </li>
                                              {el.children.map((el, i) => (
                                                <li
                                                  key={`nested${i}`}
                                                  className="nav-list__item"
                                                >
                                                  <Link to={el.path}>
                                                    <MenuLinkWithDot>
                                                      <Dot />
                                                      <Text.Medium>
                                                        {el.name}
                                                      </Text.Medium>
                                                    </MenuLinkWithDot>
                                                  </Link>
                                                </li>
                                              ))}
                                            </div>
                                          ) : (
                                            <li
                                              key={`nested${i}`}
                                              className="nav-list__item"
                                            >
                                              <Link to={el.path}>
                                                <MenuLink>
                                                  <Text.Medium bold>
                                                    {el.name}
                                                  </Text.Medium>
                                                </MenuLink>
                                              </Link>
                                            </li>
                                          )
                                        )
                                      : null}
                                  </CollapseBlock>
                                ) : (
                                  <li className="nav-list__item">
                                    <Link
                                      partiallyActive
                                      activeClassName="active"
                                      to={path}
                                    >
                                      <MenuLink>
                                        <Text.Medium bold>{name}</Text.Medium>
                                      </MenuLink>
                                    </Link>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                          <HeaderPickUpSm
                            isLoggedOff={!loggedUserName}
                            onClickLoginButton={tglPopup}
                          />
                          <div className="header-soc">
                            <SocialList />
                          </div>
                        </nav>
                      </>,
                      document.body
                    )
                  : null}
                {wWidth && wWidth < 1024 && (
                  <ButtonGroup>
                    {loggedUserName ? (
                      <LKEnter
                        onlyAvatar
                        toggleLkEnterPopup={toggleLkEnterPopup}
                      />
                    ) : (
                      <ButtonLogin onClick={tglPopup}>Войти</ButtonLogin>
                    )}
                    <button
                      className={`${classBurger} header__burger`}
                      onClick={handleTglMenu}
                    >
                      <div className="btn-burger__icon">
                        <span></span>
                      </div>
                    </button>
                  </ButtonGroup>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isShowing && <AlreadyWorkingModal hide={tglPopup} />}
      {lkEnterPopupShowing && (
        <LKEnterPopup isMobile hide={toggleLkEnterPopup} />
      )}
    </>
  )
}

export default Header
