import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import { documents } from '../../static/db.json'
import { useEffect } from 'react'

const consentModeConfig = {
  guiOptions: {
    consentModal: {
      layout: 'bar inline',
      position: 'bottom',
      equalWeightButtons: false,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  categories: {
    analytics: {
      enabled: true
    },
    marketing: {
      enabled: true
    }
  },

  language: {
    default: 'ru',

    translations: {
      ru: {
        consentModal: {
          description: `
            <p style="font-weight: 600; color: #2c2f31; font-size: 16.8px;">
              Мы используем cookie
            </p>
            <br/>
            <br/>
            Куки помогают сайту работать лучше,
            так же как психологи помогают повысить качество жизни. 
            О том, какие данные мы собираем, можно прочитать в нашей 
            <a href="${documents.urlPolicy}">Политике конфиденциальности</a>.
          `,
          acceptAllBtn: 'Принять все',
          acceptNecessaryBtn: 'Отклонить все',
          showPreferencesBtn: 'Настроить вручную'
        },
        preferencesModal: {
          title: 'Настройки Cookie',
          acceptAllBtn: 'Принять все',
          acceptNecessaryBtn: 'Отклонить все',
          savePreferencesBtn: 'Сохранить настройки',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Использование файлов cookie',
              description:
                'Когда вы посещаете любой веб-сайт, он может хранить или извлекать информацию в вашем браузере, в основном в виде файлов cookie. Эта информация может касаться вас, ваших предпочтений или вашего устройства и используется для того, чтобы сайт работал так, как вы ожидаете. Информация не идентифицирует вас напрямую, но может предоставить вам более персонализированный веб-опыт. Поскольку мы уважаем ваше право на конфиденциальность, вы можете выбрать, чтобы не разрешать некоторые типы файлов cookie. Нажмите на разные категории, чтобы узнать больше и изменить настройки по умолчанию.'
            },
            {
              title: 'Аналитические файлы cookie',
              description:
                'Эти файлы cookie позволяют нам отслеживать аналитику, считая посещения и источники трафика, чтобы мы могли измерять и улучшать производительность нашего веб-сайта. Они могут устанавливаться нами или сторонними поставщиками, чтобы помочь нам понять, какие страницы самые популярные, а какие наименее посещаемые, и увидеть, как посетители перемещаются по веб-сайту.',
              linkedCategory: 'analytics'
            },
            {
              title: 'Рекламные файлы cookie',
              description:
                'Эти файлы cookie могут устанавливаться нашими рекламными партнерами через наш сайт. Они могут использоваться этими партнерами для составления профиля ваших интересов и показа вам соответствующей рекламы на других веб-сайтах.',
              linkedCategory: 'marketing'
            }
          ]
        }
      }
    }
  }
}

export const useConsentMode = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer ?? []
    function gtag() {
      window.dataLayer.push(arguments)
    }

    const onChange = () =>
      gtag('consent', 'update', {
        ad_storage: CookieConsent.acceptedCategory('marketing')
          ? 'granted'
          : 'denied',
        ad_user_data: CookieConsent.acceptedCategory('marketing')
          ? 'granted'
          : 'denied',
        ad_personalization: CookieConsent.acceptedCategory('marketing')
          ? 'granted'
          : 'denied',
        analytics_storage: CookieConsent.acceptedCategory('analytics')
          ? 'granted'
          : 'denied'
      })
    CookieConsent.run({
      ...consentModeConfig,
      onConsent: onChange,
      onChange
    })
  })
}
