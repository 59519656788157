/* eslint-disable max-lines-per-function */
export const useNavData = () =>
  [
    {
      name: 'О нас',
      nested: [
        {
          path: '/about/',
          name: 'О проекте',
          type: 'link'
        },
        { name: 'Блог', path: '/blog/', type: 'link' },
        { name: 'Подкаст', path: '/podcast/', type: 'link' },
        { name: 'Вебинары', path: '/webinars/', type: 'link' },
        {
          name: 'Спецпроекты',
          children: [
            {
              name: 'Подвешенные сессии',
              path: 'https://promo.youtalk.ru/podveshennye-konsultaciI/',
              type: 'link'
            },
            {
              name: 'Rich',
              path: 'https://promo.youtalk.ru/rich/',
              type: 'link'
            }
          ]
        },
        {
          path: '/faq/',
          name: 'FAQ',
          type: 'link'
        }
      ]
    },
    {
      name: 'Каталог психологов',
      nested: [
        {
          path: '/catalog/',
          name: 'Все психологи',
          type: 'link'
        },
        {
          name: 'Подходы',
          children: [
            {
              path: '/kpt-kognitivno-povedencheskaia-psihoterapiia/',
              name: 'КПТ',
              type: 'link'
            },
            {
              path: '/emdr/',
              name: 'EMDR',
              type: 'link'
            },
            {
              path: '/narrativnaya-psihoterapiya/',
              name: 'Нарративная психотерапия',
              type: 'link'
            },
            {
              path: '/klient-tsentrirovannaya-psihoterapiya/',
              name: 'Клиент-центрированная психотерапия',
              type: 'link'
            },
            {
              path: '/act-terapiya-prinyatiya-i-otvetstvennosti/',
              name: 'Терапия принятия и ответственности',
              type: 'link'
            },
            {
              path: '/integrativnaya-psihoterapiya/',
              name: 'Интегративная психотерапия',
              type: 'link'
            },
            {
              path: '/art-terapiya/',
              name: 'Арт-терапия',
              type: 'link'
            },
            {
              path: '/pozitivnaya-psyhoterapiya/',
              name: 'Позитивная психотерапия',
              type: 'link'
            },
            {
              path: '/ekzistentsialnaya-terapiya/',
              name: 'Экзистенциальная терапия',
              type: 'link'
            },
            {
              path: '/processualnaya-terapiya/',
              name: 'Процессуальная терапия',
              type: 'link'
            },
            {
              path: '/skazkoterapiya/',
              name: 'Сказкотерапия',
              type: 'link'
            },
            {
              path: '/travmaterapiya/',
              name: 'Травматерапия',
              type: 'link'
            },
            {
              path: '/yungianskiy-analiz/',
              name: 'Юнгианский анализ',
              type: 'link'
            },
            {
              path: '/tantsevalno-dvigatelnaya-terapiya/',
              name: 'Танцевально-двигательная терапия',
              type: 'link'
            },
            {
              path: '/ponimayuschaya-psihoterapiya/',
              name: 'Понимающая терапия',
              type: 'link'
            },
            {
              path: '/geshtalt-psihoterapiya/',
              name: 'Гештальт-терапия',
              type: 'link'
            },
            {
              path: '/racionalno-emocionalnaya-povedencheskaya-terapiya/',
              name: 'Рационально-эмоциональная поведенческая терапия',
              type: 'link'
            },
            {
              path: '/psihoanaliz-i-psihoanaliticheskaya-terapiya/',
              name: 'Психоанализ и психоаналитическая терапия',
              type: 'link'
            },
            {
              path: '/orientirovannaya-na-reshenie-kratkosrochnaya-terapiya/',
              name: 'Ориентированная на решение краткосрочная терапия',
              type: 'link'
            },
            {
              path: '/gumanisticheskaya-terapiya/',
              name: 'Гуманистическая терапия',
              type: 'link'
            },
            {
              path: '/sistemnaya-semejnaya-psihoterapiya/',
              name: 'Системная семейная психотерапия',
              type: 'link'
            },
            {
              path: '/telesno-orientirovannaya-psihoterapiya/',
              name: 'Телесно-ориентированная психотерапия',
              type: 'link'
            },
            {
              path: '/lichnostno-orientirovannaya-terapiya/',
              name: 'Личностно-ориентированная терапия',
              type: 'link'
            },
            {
              path: '/simvoldrama/',
              name: 'Символдрама',
              type: 'link'
            },
            {
              path: '/tranzaktnyy-analiz/',
              name: 'Транзактный анализ',
              type: 'link'
            },
            {
              path: '/psihodrama/',
              name: 'Психодрама',
              type: 'link'
            }
          ]
        },
        {
          name: 'Психологи',
          children: [
            {
              path: '/semeinyi-psiholog/',
              name: 'Семейный психолог',
              type: 'link'
            },
            {
              path: '/psihoterapevt/',
              name: 'Психотерапевт',
              type: 'link'
            },
            {
              path: '/psiholog-seksolog/',
              name: 'Психолог-сексолог',
              type: 'link'
            },
            {
              path: '/kognitivnii-psiholog/',
              name: 'Когнитивный психолог',
              type: 'link'
            },
            {
              path: '/lichnii-psiholog/',
              name: 'Личный психолог',
              type: 'link'
            },
            {
              path: '/krizisnii-psiholog/',
              name: 'Кризисный психолог',
              type: 'link'
            },
            {
              path: '/biznes-psiholog/',
              name: 'Бизнес-психолог',
              type: 'link'
            },
            {
              path: '/klinicheskii-psiholog/',
              name: 'Клинический психолог',
              type: 'link'
            },
            {
              path: '/perinatalnii-psiholog/',
              name: 'Перинатальный психолог',
              type: 'link'
            },
            {
              path: '/pravoslavnyy-psiholog/',
              name: 'Православный психолог',
              type: 'link'
            },
            {
              path: '/kouch/',
              name: 'Коуч',
              type: 'link'
            },
            {
              path: '/psiholog-dlya-zhenschin/',
              name: 'Психолог женщинам',
              type: 'link'
            },
            {
              path: '/psiholog-dlya-muzhchin/',
              name: 'Психолог для мужчин',
              type: 'link'
            },
            {
              path: '/psiholog-dlya-roditeley/',
              name: 'Психолог для родителей',
              type: 'link'
            },
            {
              path: '/psiholog-dlya-pozhilyh-lyudey/',
              name: 'Психолог для пожилых людей',
              type: 'link'
            },
            {
              path: '/analiticheskiy-psiholog/',
              name: 'Аналитический психолог',
              type: 'link'
            },
            {
              path: '/psiholog-ekzistentsialist/',
              name: 'Психолог экзистенциалист',
              type: 'link'
            },
            {
              path: '/anonimnyy-psiholog/',
              name: 'Анонимный психолог',
              type: 'link'
            },
            {
              path: '/psiholog-konfliktolog/',
              name: 'Психолог конфликтолог',
              type: 'link'
            },
            {
              path: '/psihoanalitik/',
              name: 'Психоаналитик',
              type: 'link'
            },
            {
              path: '/sportivnyy-psiholog/',
              name: 'Спортивный психолог',
              type: 'link'
            }
          ]
        },
        {
          name: 'Форматы',
          children: [
            {
              path: '/psiholog-v-chate/',
              name: 'Психолог в чате',
              type: 'link'
            },
            {
              path: '/whatsapp-psiholog/',
              name: 'Психолог по WhatsApp',
              type: 'link'
            },
            {
              path: '/psiholog-telegram/',
              name: 'Психолог в телеграм',
              type: 'link'
            },
            {
              path: '/psiholog-po-perepiske/',
              name: 'Психолог по переписке',
              type: 'link'
            },
            {
              path: '/psiholog-po-skype/',
              name: 'Психолог по Skype',
              type: 'link'
            },
            {
              path: '/video-konsultaciya-s-psihologom/',
              name: 'Видео консультация',
              type: 'link'
            },
            {
              path: '/psiholog-po-zoom/',
              name: 'Психолог по Зуму (Zoom)',
              type: 'link'
            }
          ]
        },
        {
          name: 'Проблемы и запросы',
          children: [
            {
              path: '/panicheskie-ataki/',
              name: 'Панические атаки',
              type: 'link'
            },
            {
              path: '/trevojnost/',
              name: 'Тревожность',
              type: 'link'
            },
            {
              path: '/depressiya/',
              name: 'Депрессия',
              type: 'link'
            },
            {
              path: '/razvod/',
              name: 'Развод',
              type: 'link'
            },
            {
              path: '/travma/',
              name: 'Травма',
              type: 'link'
            },
            {
              path: '/apatiya/',
              name: 'Апатия',
              type: 'link'
            },
            {
              path: '/aviafobiya-aerofobiya/',
              name: 'Аэрофобия',
              type: 'link'
            },
            {
              path: '/dentofobiya/',
              name: 'Дентофобия',
              type: 'link'
            },
            {
              path: '/strahi-fobii/',
              name: 'Страхи и фобии',
              type: 'link'
            },
            {
              path: '/stress/',
              name: 'Стресс',
              type: 'link'
            },
            {
              path: '/psihologicheskie-pischevye-narusheniya/',
              name: 'Психологические пищевые нарушения',
              type: 'link'
            },
            {
              path: '/onkologicheskim-bolnym/',
              name: 'Онкологическим больным',
              type: 'link'
            },
            {
              path: '/igrovaya-zavisimost/',
              name: 'Игровая зависимость',
              type: 'link'
            },
            {
              path: '/izmena/',
              name: 'При измене',
              type: 'link'
            },
            {
              path: '/smert-blizkogo/',
              name: 'Смерть близкого',
              type: 'link'
            },
            {
              path: '/gemofobiya-lechenie/',
              name: 'Гемофобия лечение',
              type: 'link'
            },
            {
              path: '/arahnofobiya-lechenie/',
              name: 'Арахнофобия лечение',
              type: 'link'
            },
            {
              path: '/tanatofobiya-lechenie/',
              name: 'Танатофобия лечение',
              type: 'link'
            },
            {
              path: '/agorafobiya-lechenie/',
              name: 'Агорафобия лечение',
              type: 'link'
            }
          ]
        }
      ]
    },
    {
      path: '/tariff/',
      name: 'Тарифы',
      type: 'link'
    },
    {
      path: '/b2b/',
      name: 'Для\u00a0бизнеса',
      type: 'link'
    },
    {
      path: '/be-in-team/',
      name: 'Психологам',
      type: 'link'
    },
    {
      path: '/sertifikat-na-psihoterapiyu/',
      name: 'Сертификат',
      type: 'link'
    }
  ].filter(Boolean)
